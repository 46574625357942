import { CharStatus } from '../../lib/statuses'
import classnames from 'classnames'
import { REVEAL_TIME_MS } from '../../constants/settings'
import { getStoredIsHighContrastMode } from '../../lib/localStorage'

type Props = {
  value?: string
  status?: CharStatus
  isRevealing?: boolean
  isCompleted?: boolean
  position?: number
}

export const Cell = ({
  value,
  status,
  isRevealing,
  isCompleted,
  position = 0,
}: Props) => {
  const isFilled = value && !isCompleted
  const shouldReveal = isRevealing && isCompleted
  const animationDelay = `${position * REVEAL_TIME_MS}ms`
  const isHighContrast = getStoredIsHighContrastMode()

  const classes = classnames(
    'w-12 flex items-center justify-center mx-0.5 text-4xl font-bold rounded-lg text-white',
    {
      // 'border-b-8 bg-white border-slate-200 shadow-slate-200/50':
      //   value || !status,
      // 'absent text-black border-b-8 bg-absent-500 border-absent-800 shadow-absent-600/50':
      //   status === 'absent',
      // 'correct text-white border-b-8 bg-bener-500 border-bener-800 shadow-bener-600/50':
      //   status === 'correct',
      // 'present text-white border-b-8 bg-aya-500 border-aya-800 shadow-aya-600/50':
      //   status === 'present',
      'cell-fill-animation': isFilled,
      'cell-reveal': shouldReveal,
    }
  )

  let bgImage = ''
  if (status === 'absent') {
    bgImage = `url(${require('../../assets/Block-grey.png')})`
  } else if (status === 'correct') {
    bgImage = `url(${require('../../assets/Block-green.png')})`
  } else if (status === 'present') {
    bgImage = `url(${require('../../assets/Block-yellow.png')})`
  } else if(value || !status) {
    bgImage = `url(${require('../../assets/Block-brown.png')})`
  }

  return (
    <div className={classes} style={{ animationDelay, height: 54, backgroundImage: bgImage, backgroundSize: '100%', backgroundPosition: 'top center', fontFamily: "'Lilita One', sans-serif", fontWeight: 400, backgroundRepeat: 'no-repeat', }}>
      <div className="letter-container" style={{ animationDelay }}>
        {value}
      </div>
    </div>
  )
}
