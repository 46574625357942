import { useCallback, useContext, useMemo, useState } from 'react'
import cx from 'classnames'

export interface iPowerupItemProps {
  onConsumePowerup: (e: any, powerup: any) => void
  powerup: {
    id: string
    key_id: string
    amount: number | string
    iconUrl: string
    active: boolean
  }
}

export const PowerupItem = ({
  onConsumePowerup,
  powerup,
}: iPowerupItemProps) => {
  return (
    <a
      href="javascript:void(0)"
      className={cx('flex text-sm text-white font-bold mx-1', {
        'powerup-disabled': !powerup.active,
      })}
      style={{
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        lineHeight: 1,
        position: 'relative',
      }}
      onClick={(e) =>
        powerup.active ? onConsumePowerup(e, powerup) : undefined
      }
    >
      {/* {powerup.description} x {powerup.amount} */}
      <img src={powerup.iconUrl} width={40} />
      <span
        style={{
          position: 'absolute',
          top: 4,
          right: -7,
          padding: '0 5px',
          background: 'red',
          borderRadius: 12,
          fontSize: 9,
          height: 15,
          lineHeight: 18 / 10,
          textAlign: 'center',
        }}
      >
        {powerup.amount}
      </span>
    </a>
  )
}
