import { unicodeSplit } from './words'

export type CharStatus = 'absent' | 'present' | 'correct'

export const getLetterStatuses = (
  letters: string[],
  solution: string[],
  guesses: string[],
  hints: string[]
): { [key: string]: CharStatus } => {
  const charObj: { [key: string]: CharStatus } = {}
  const splitSolution = unicodeSplit(solution.join(''))

  letters.forEach((letter, i) => {
    // if (letter === splitSolution[i]) {
    //   //make status correct
    //   return (charObj[letter] = 'correct')
    // }

    if (charObj[letter] !== 'correct') {
      if (!splitSolution.includes(letter)) {
        // make status absent
        return (charObj[letter] = 'absent')
      }

      if (splitSolution.includes(letter)) {
        //make status present
        return (charObj[letter] = 'present')
      }
    }
  })

  // Override present word
  guesses.forEach((word) => {
    word.split('').forEach((letter, i) => {
      if (letter === splitSolution[i]) {
        //make status correct
        return (charObj[letter] = 'correct')
      }
    })
  })

  hints.forEach((letter, i) => {
    if (letter === splitSolution[i]) {
      //make status correct
      return (charObj[letter] = 'correct')
    }
  })

  return charObj
}

export const getStatuses = (
  guesses: string[],
  solution: string[]
): { [key: string]: CharStatus } => {
  const charObj: { [key: string]: CharStatus } = {}
  const splitSolution = unicodeSplit(solution.join(''))

  guesses.forEach((word) => {
    unicodeSplit(word).forEach((letter, i) => {
      if (!splitSolution.includes(letter)) {
        // make status absent
        return (charObj[letter] = 'absent')
      }

      if (letter === splitSolution[i]) {
        //make status correct
        return (charObj[letter] = 'correct')
      }

      if (charObj[letter] !== 'correct') {
        //make status present
        return (charObj[letter] = 'present')
      }
    })
  })

  return charObj
}

export const getGuessStatuses = (
  guess: string,
  solution: string[] = []
): CharStatus[] => {
  const splitSolution = unicodeSplit(
    solution.map((d) => (d ? d : '-')).join('')
  )
  const splitGuess = unicodeSplit(guess)
  const solutionCharsTaken = splitSolution.map((_) => false)
  const statuses: CharStatus[] = Array.from(Array(guess.length))

  // handle all correct cases first
  splitGuess.forEach((letter, i) => {
    if (letter === splitSolution[i]) {
      statuses[i] = 'correct'
      solutionCharsTaken[i] = true
      return
    }
  })

  splitGuess.forEach((letter, i) => {
    if (statuses[i]) return

    if (!splitSolution.includes(letter)) {
      // handles the absent case
      statuses[i] = 'absent'
      return
    }

    // now we are left with "present"s
    const indexOfPresentChar = splitSolution.findIndex(
      (x, index) => x === letter && !solutionCharsTaken[index]
    )

    if (indexOfPresentChar > -1) {
      statuses[i] = 'present'
      solutionCharsTaken[indexOfPresentChar] = true
      return
    } else {
      statuses[i] = 'absent'
      return
    }
  })

  return statuses
}
