import { BaseModal } from './BaseModal'
import * as d3 from 'd3'
import { useMemo, useCallback, useContext } from 'react'
import { useWindowDimensions } from '../../hooks/dimension.hook'
import { AppContext } from '../../app.context'
import cx from 'classnames'

type Props = {
  isOpen: boolean
  text: string
  handleCancel?: () => void
  handleAds?: () => void
}

export const ErrorModal = ({
  isOpen,
  text,
  handleCancel = () => undefined,
  handleAds,
}: Props) => {
  const { player } = useContext(AppContext)
  const { width, height } = useWindowDimensions()
  const { room, onExit, goToShop} = useContext(AppContext)
  const popupSize = useMemo(() => {
    return width * 0.8
  }, [width])

  const scale = useCallback(
    (value: number) => {
      const _scale = d3.scaleLinear().domain([0, 600]).range([0, popupSize])
      return _scale(value)
    },
    [popupSize]
  )

  const disableAd = useMemo(() => {
    return player.remain_ads === 0
  }, [player])

  return (
    <BaseModal
      isOpen={isOpen}
      handleClose={handleCancel}
      containerStyle={{ padding: 16 }}
    >
      <div className="relative">
        <img src={require('../../assets/X-Button.png')} width={34} 
          style={{
            position: 'absolute',
            left: scale(-43),
            top: scale(120),
          }} 
          alt=""
          onClick={handleCancel}
        />
        <img src={require('../../assets/Main-Body-toko.png')} width={popupSize} alt="" />
        <img src={require('../../assets/Header-th.png')} 
          style={{
            position: 'absolute',
            left: '50%',
            top: scale(115),
            transform: 'translate(-50%, 0)',
            width: '135px'
          }}  alt="" />
        <div
          className="text-white"
          style={{
            position: 'absolute',
            left: '50%',
            top: scale(235),
            transform: 'translate(-50%, 0)',
            fontWeight: '400',
            fontSize: 18,
            color: '#6d1a12',
            fontFamily: "'Lilita One', sans-serif",
          }}
        >
          {text}
        </div>
        <div
          style={{
            position: 'absolute',
            left: '50%',
            bottom: scale(70),
            transform: 'translate(-50%, 0)',
            width: '100%',
            zIndex: 10
          }}
        >
          <div
            onClick={goToShop}
            style={{
              backgroundColor: 'transparent',
              height: scale(180),
              width: scale(180),
              display: 'inline-block',
              margin: '5px',
              position: 'relative'
            }}
          >
            <img src={require('../../assets/toko.png')} alt=""/>
          </div>
          
          { handleAds && (
            <div
              className={cx({
                'btn-ad-disabled': disableAd
              })}
              style={{
                backgroundColor: 'transparent',
                height: scale(180),
                width: scale(180),
                display: 'inline-block',
                margin: '5px',
                position: 'relative'
              }}
              onClick={disableAd ? undefined : handleAds}
            >
              <img src={require('../../assets/nonton.png')} alt="" />
              <span 
                style={{
                  display: 'inline-block',
                  position: 'absolute',
                  marginLeft: '2%',
                  width: '16px',
                  height: '16px',
                  borderRadius: '50%',
                  color: '#6d1a12',
                  fontSize: '11px',
                  fontFamily: "'Lilita One', sans-serif",
                  top: 18,
                  right: 18,
                }}
              >
                {player.remain_ads}
              </span>
            </div>
          )}
        </div>
      </div>
    </BaseModal>
  )
}
