import cx from 'classnames'
import { time } from 'console'
import { useContext, useEffect, useState } from 'react'
import { AppContext } from '../../app.context'

export const Header = ({ title, onBack }: any) => {
  const { player, room, level } = useContext(AppContext)
  const [timer, setTimer] = useState<string | undefined>()

  useEffect(() => {
    // console.log(`ini isi nya apa aja sih?`, player, player.diamond)
    room?.onMessage('timer:update', (data) => {
      console.log('timer:update', data)
    })
    // console.log(`heiii`, player)
  }, [room])

  if (!player) {
    return null
  }

  return (
    <>
      <div className="max-w flex justify-center align-items-center align-middle">
        <div style={{ width: 60 }}>
          {onBack && (
            <button
              className="text-white font-bold rounded p-2"
              onClick={onBack}
            >
              <img src={require('../../assets/icon-exit.png')} width={48} alt="" />
            </button>
          )}
        </div>
        {/*<div className="flex-1 text-center justify-center items-center text-xl text-bold flex">
          {timer && <span>{timer}</span>}
        </div> */}
        <div className="flex-1 flex items-center justify-end">
          <span className="text-white rounded-full py-0.5 px-2.5 inline-block mr-2" style={{ backgroundColor: '#FFEBE9' }}> 
            <span className='float-left text-black font-bold mr-1 text-sm'>
              {player.ticket_balance ? String(player.ticket_balance).length > 3 ? String(player.ticket_balance).slice(0, -3) + 'RB' : player.ticket_balance : 0}/12
            </span> 
            <img src={require('../../assets/ticket-free.png')} width={16} className='float-right mt-0.5' alt=""/>  
          </span>
          <span className="text-white rounded-full py-0.5 px-2.5 inline-block mr-2" style={{ backgroundColor: '#FFEBE9' }}> 
            <span className='float-left text-black font-bold mr-1 text-sm'>
              {player.ticket_plus_balance ? String(player.ticket_plus_balance).length > 3 ? String(player.ticket_plus_balance).slice(0, -3) + 'RB' : player.ticket_plus_balance : 0}
            </span> 
            <img src={require('../../assets/ticket-plus.png')} width={16} className='float-right mt-0.5' alt="" />  
          </span>
          <span className="text-white rounded-full py-0.5 px-2.5 inline-block mr-2" style={{ backgroundColor: '#FFEBE9' }}> 
            <span className='float-left text-black font-bold mr-1 text-sm'>
              {player.diamond ? String(player.diamond).length > 3 ? String(player.diamond).slice(0, -3) + 'RB' : player.diamond : 0}
            </span> 
            <img src={require('../../assets/yo-gem.png')} width={16} className='float-right mt-0.5' alt=""/>  
          </span>
          <span className="text-white rounded-full py-0.5 px-2.5 inline-block mr-2" style={{ backgroundColor: '#FFEBE9' }}> 
            <span className='float-left text-black font-bold mr-1 text-sm'>
              {player.yo_token ? String(player.yo_token).length > 3 ? String(player.yo_token).slice(0, -3) + 'RB' : player.yo_token : 0}
            </span> 
            <img src={require('../../assets/yo-token.png')} width={16} className='float-right mt-0.5' alt=""/>  
          </span>
        </div>
      </div>
    </>
  )
}
