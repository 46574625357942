import { ReactNode } from 'react'
import classnames from 'classnames'
import { CharStatus } from '../../lib/statuses'
import { MAX_WORD_LENGTH, REVEAL_TIME_MS } from '../../constants/settings'
import { getStoredIsHighContrastMode } from '../../lib/localStorage'

type Props = {
  children?: ReactNode
  value: string
  width?: number
  status?: CharStatus
  onClick: (value: string) => void
  isRevealing?: boolean
  maxWord: number
  identity: string,
  handleDisable: boolean
}

export const Key = ({
  children,
  status,
  width = 40,
  value,
  onClick,
  isRevealing,
  maxWord,
  identity,
  handleDisable
}: Props) => {
  const keyDelayMs = REVEAL_TIME_MS * maxWord
  const isHighContrast = getStoredIsHighContrastMode()

  const classes = classnames(
    `flex items-center justify-center rounded mx-0.5 text-xs font-bold cursor-pointer select-none dark:text-white ${identity}`,
    {
      'transition ease-in-out': isRevealing,
      'bg-white dark:bg-slate-600 hover:bg-slate-300 active:bg-slate-400':
        !status,
      'bg-slate-400 dark:bg-slate-800 text-white': status === 'absent',
      'bg-bener-500 hover:bg-bener-600 active:bg-bener-700 text-white':
        status === 'correct',
      'bg-aya-500 hover:bg-aya-600 active:bg-aya-700 text-white':
        status === 'present',
    }
  )

  const styles = {
    transitionDelay: isRevealing ? `${keyDelayMs}ms` : 'unset',
    width: `${width}px`,
    height: '58px',
  }

  const handleClick: React.MouseEventHandler<HTMLButtonElement> = (event) => {
    onClick(value)
    event.currentTarget.blur()
  }

  return (
    <button style={styles} className={classes} onClick={handleClick} disabled={handleDisable}>
      {children || value}
    </button>
  )
}
