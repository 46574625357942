import { BaseModal } from './BaseModal'
import * as d3 from 'd3'
import { useMemo, useCallback, useContext, useState } from 'react'
import { useWindowDimensions } from '../../hooks/dimension.hook'
import cx from 'classnames'
import { AppContext } from '../../app.context'
import { relative } from 'path'
import ReactPlayer from 'react-player'

type Props = {
  isOpen: boolean
  handleCancel?: () => void
  handleBack?: () => void
  handleAds?: () => void
  handleOk?: (level: any) => void
  handleDismiss?: () => void
}

export const LevelFailedModal = ({
  isOpen,
  handleCancel = () => undefined,
  handleBack,
  handleAds,
  handleOk,
  handleDismiss = () => undefined
}: Props) => {
  const { player, island, level, onSelectLevel } = useContext(AppContext)
  const { width, height } = useWindowDimensions()
  const [selectedLevel, setSelectedLevel] = useState(undefined)

  const popupSize = useMemo(() => {
    return width * 0.8
  }, [width])

  const scale = useCallback(
    (value: number) => {
      const _scale = d3.scaleLinear().domain([0, 600]).range([0, popupSize])
      return _scale(value)
    },
    [popupSize]
  )

  const disableAd = useMemo(() => {
    return player.ticket_balance > 0 && player.ticket_plus_balance > 0
  }, [player])

  const headPopup = () => {
    const random = Math.floor(Math.random() * 3) + 1;
    switch (random) {
      case 1:
        return require('../../assets/kalah-1.png')
      case 2:
        return require('../../assets/kalah-1.png')
      case 3:
        return require('../../assets/kalah-1.png')
    }
  }

  return (
    <BaseModal isOpen={isOpen} handleClose={handleDismiss} transparent={true}>
      <div className="relative">
        <img src={require('../../assets/X-Button.png')} width={34} 
          style={{
            position: 'absolute',
            left: scale(-43),
            top: scale(120),
            zIndex: 9
          }} 
          alt=""
          onClick={handleDismiss}
        />
        <img src={require('../../assets/Main_Body_UI.png')} width={popupSize} alt="" />
        {/* <ReactPlayer url={require('../../assets/GUI-TEBAK-YO-KALAH-birthday.webm')} loop playing muted width='100vw' height='calc((16/9) * 100vw)' */}
        <ReactPlayer url={require('../../assets/GUI-KALAH.webm')} loop playing muted width='100vw' height='calc((16/9) * 100vw)'
        style={{position: 'absolute', top: scale(-470), left: '50%', transform: 'translateX(-50%)' }} onError={(e) => console.log(e)}/>

        <div
          style={{
            position: 'absolute',
            left: '50%',
            transform: 'translate(-50%, 0)',
            top: scale(115),
            backgroundImage: `url(${require('../../assets/Header-Kalah.png')})`,
            backgroundSize: '100%',
            width: '135px',
            height: '45px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center'
          }}
        >
          <img src={headPopup()} 
            style={{
              width: '85px'
            }}  alt="" />
        </div>
        
        <div
          style={{
            position: 'absolute',
            left: '50%',
            top: scale(260),
            transform: 'translate(-50%, 0)',
            zIndex: 9,
            color: '#6d1a12',
            fontFamily: "'Lilita One', sans-serif",
            width: '80%',
            fontSize: 15,
            fontWeight: 400,
          }}
        >
          Yaaah Dikit lagi, Coba lagi nanti ya!
        </div>

        <div
          style={{
            position: 'absolute',
            left: '50%',
            bottom: scale(90),
            transform: 'translate(-50%, 0)',
            width: '100%',
            zIndex: 10
          }}
        >
          <div
            style={{
              backgroundColor: 'transparent',
              height: scale(80),
              width: scale(184),
              display: 'inline-block',
              margin: '5px',
              position: 'relative'
            }}
            onClick={() => handleCancel?.()}
          >
            <div
              style={{
                width: '100px',
                height: '43px',
                display: 'inline-block',
                verticalAlign: 'middle',
                backgroundImage: `url(${require('../../assets/OK-Button.png')})`,
                backgroundSize: '100%',
                backgroundRepeat: 'no-repeat',
                padding: '0px',
                margin: '5px',
              }}
            />
          </div>

          {/* <div
            onClick={() => handleBack?.()}
            style={{
              backgroundColor: 'transparent',
              height: scale(80),
              width: scale(184),
              display: 'inline-block',
              margin: '5px',
              position: 'relative'
            }}
          >
            <img src={require('../../assets/Tidak-Button.png')} alt="" />
          </div> */}
        </div>
      </div>
    </BaseModal>
  )
}
