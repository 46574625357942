import { BaseModal } from './BaseModal'
import * as d3 from 'd3'
import { useMemo, useCallback, useContext } from 'react'
import { useWindowDimensions } from '../../hooks/dimension.hook'
import { AppContext } from '../../app.context'

type Props = {
  isOpen: boolean
  title?: string
  text?: string
  handleCancel?: () => void
  handleOk?: () => void
}

export const ConfirmModal = ({
  isOpen,
  title = "Oops!",
  text,
  handleCancel = () => undefined,
  handleOk = () => undefined,
}: Props) => {
  const { width, height } = useWindowDimensions()
  const popupSize = useMemo(() => {
    return width * 0.8
  }, [width])

  const scale = useCallback(
    (value: number) => {
      const _scale = d3.scaleLinear().domain([0, 600]).range([0, popupSize])
      return _scale(value)
    },
    [popupSize]
  )

  return (
    <BaseModal
      isOpen={isOpen}
      handleClose={handleCancel}
      containerStyle={{ padding: 16 }}
    >
      <div className="relative">
        <img src={require('../../assets/X-Button.png')} width={34} 
          style={{
            position: 'absolute',
            left: scale(-43),
            top: scale(120),
          }} 
          alt=""
          onClick={handleCancel}
        />
        <img src={require('../../assets/Main_Body_UI.png')} width={popupSize} alt="" />
        <img src={require('../../assets/Header-Exit.png')} 
          style={{
            position: 'absolute',
            left: '50%',
            top: scale(115),
            transform: 'translate(-50%, 0)',
            width: '135px'
          }}  alt="" />
        <div
          className="text-white"
          style={{
            position: 'absolute',
            left: '50%',
            top: scale(260),
            transform: 'translate(-50%, 0)',
            fontWeight: '400',
            fontSize: 18,
            color: '#6d1a12',
            fontFamily: "'Lilita One', sans-serif",
          }}
        >
          { title }
        </div>

        { text && (
          <div
            style={{
              position: 'absolute',
              left: '50%',
              top: scale(320),
              transform: 'translate(-50%, 0)',
              fontWeight: '700',
              fontSize: 18,
              color: '#FFF',
              fontFamily: "'Encode Sans Semi Condensed', sans-serif",
              textShadow: "1px 1px #54101B",
            }}
          >
            {text}
          </div>
        )}

        <div
          style={{
            position: 'absolute',
            left: '50%',
            bottom: scale(80),
            transform: 'translate(-50%, 0)',
            width: '100%',
            zIndex: 10
          }}
        >
          <div
            style={{
              backgroundColor: 'transparent',
              height: '41px',
              width: '90px',
              display: 'inline-block',
              margin: '5px',
              position: 'relative'
            }}
            onClick={handleOk}
          >
            <img src={require('../../assets/Ya-Button.png')} width={popupSize} alt="" />
          </div>
          <div
            onClick={handleCancel}
            style={{
              backgroundColor: 'transparent',
              height: '41px',
              width: '90px',
              display: 'inline-block',
              margin: '5px',
              position: 'relative'
            }}
          >
            <img src={require('../../assets/Tidak-Button.png')} width={popupSize} alt=""/>
          </div>

        </div>
      </div>
    </BaseModal>
  )
}
