import {
  useState,
  useEffect,
  useContext,
  useMemo,
  useCallback,
  useRef,
  createRef,
} from 'react'
import { Grid } from '../../components/grid/Grid'
import { Keyboard } from '../../components/keyboard/Keyboard'
import { NOT_ENOUGH_LETTERS_MESSAGE } from '../../constants/strings'
import { REVEAL_TIME_MS } from '../../constants/settings'
import { unicodeLength } from '../../lib/words'
import { loadStats } from '../../lib/stats'
import { getStoredIsHighContrastMode } from '../../lib/localStorage'
import { default as GraphemeSplitter } from 'grapheme-splitter'
import './Wordle.css'
import { useAlert } from '../../context/AlertContext'
import * as Colyseus from 'colyseus.js'
import titleImg from '../../assets/title.svg'
import { AppContext } from '../../app.context'
import { Header } from '../../components/header/header'
import { chain, sample, uniq } from 'lodash'
import { LevelFinishedModal } from '../../components/modals/LevelFinishedModal'
import cx from 'classnames'
import * as d3 from 'd3'
import { useWindowDimensions } from '../../hooks/dimension.hook'
import { LevelFailedModal } from '../../components/modals/LevelFailedModal'
import { Powerup } from './Powerup'
import { ErrorModal } from '../../components/modals/ErrorModal'
import Joyride, { CallBackProps, STATUS, Step, EVENTS, ACTIONS } from 'react-joyride';
import {useMount} from 'react-use';
import { relative } from 'path'
import { ErrorGenericModal } from '../../components/modals/ErrorGenericModal'
import { AdditionalAttemptModal } from '../../components/modals/AdditionalAttemptModal'
import pattern from '../../assets/seamless-pattern.jpg'

declare global {
  interface Window {
    flutter_inappwebview: any
  }
}

export enum StatusTutorial {
  INIT = 'tutorial_init',
  R = 'tutorial_r',
  A = 'tutorial_a',
  G = 'tutorial_g',
  U = 'tutorial_u',
  ENTER = 'tutorial_enter',
  GUESS = 'tutorial_guess',
  DONE = 'tutorial_done',
  TAHI = 'tutorial_tahi'
}

// let tutorial = false
let currentStat = ''
let timerTutorial: number = 500
// let guessTutorialIndex = 0

const initSteps: Step[] = [
  {
    disableBeacon: true,
    content: <h2>Selamat datang di Tebak Yo! Klasik</h2>,
    placement: 'center',
    target: 'body',
  },
  {
    disableBeacon: true,
    content: <h2>Ini adalah kotak alat bantu</h2>,
    placement: 'bottom',
    target: '.demo__hints',
  },
  {
    disableBeacon: true,
    content: <h2>Ini adalah tempat kamu menebak kata</h2>,
    placement: 'bottom',
    target: '.demo__guesses',
  },
  {
    disableBeacon: true,
    content: <h2>Berikut adalah booster yang bisa Kamu gunakan</h2>,
    placement: 'top',
    target: '.demo__powerups',
  },
  {
    disableBeacon: true,
    content: <h2>Ketikan jawabanmu menggunakan keyboard ini</h2>,
    placement: 'top',
    target: '.demo__keyboards',
  },
  {
    disableBeacon: true,
    content: <h2>Sekarang mari kita mulai untuk menebak kata</h2>,
    placement: 'top',
    target: '.demo__keyboards',
  },
]

const stepR: Step[] = [
  {
    disableBeacon: true,
    hideFooter: true,
    content: <h2>Sekarang coba tap huruf R</h2>,
    placement: 'bottom',
    target: '.keyboard_r',
  },
]

const stepA: Step[] = [
  {
    disableBeacon: true,
    hideFooter: true,
    content: <h2>Sekarang coba tap A</h2>,
    placement: 'bottom',
    target: '.keyboard_a',
  },
]

const stepG: Step[] = [
  {
    disableBeacon: true,
    hideFooter: true,
    content: <h2>Sekarang coba tap G</h2>,
    placement: 'bottom',
    target: '.keyboard_g',
  },
]

const stepU: Step[] = [
  {
    disableBeacon: true,
    hideFooter: true,
    content: <h2>Sekarang coba tap U</h2>,
    placement: 'bottom',
    target: '.keyboard_u',
  },
]

const stepEnter: Step[] = [
  {
    disableBeacon: true,
    hideFooter: true,
    content: <h2>Kemudian tap Enter</h2>,
    placement: 'top',
    target: '.keyboard_enter',
  },
]

const stepDone: Step[] = [
  {
    disableBeacon: true,
    content: <div>
      <h2 className='pb-2'>Hasil Tebakanmu</h2>
      <ul style={{ textAlign: 'left'}}>
        <li><span className="greens">Hijau</span>: Huruf ada dalam kata kunci dan posisi sudah benar</li>
      </ul>
      </div>,
    placement: 'top',
    target: '.guesses_0',
  },
  {
    disableBeacon: true,
    content: <div>
      <h2 className='pb-2'>Hasil Tebakanmu</h2>
      <ul style={{ textAlign: 'left'}}>
        <li><span className="yellows">Kuning</span>: Huruf ada dalam kata kunci tapi posisi kurang tepat</li>
      </ul>
      </div>,
    placement: 'top',
    target: '.guesses_0',
  },
  {
    disableBeacon: true,
    content: <div>
      <h2 className='pb-2'>Hasil Tebakanmu</h2>
      <ul style={{ textAlign: 'left'}}>
        <li><span className="grays">Abu-abu</span>: Huruf tidak ada dalam kata kunci</li>
      </ul>
      </div>,
    placement: 'top',
    target: '.guesses_0',
  },
  {
    disableBeacon: true,
    content: <h2>Sekarang mari kita mulai untuk menebak kata</h2>,
    placement: 'top',
    target: '.guesses_0',
  },
]

const stepTahi: Step[] = [
  {
    disableBeacon: true,
    content: <h2>Ini adalah mode terbaru di TebakYo!, mode TAHI! (Tanpa Hint)</h2>,
    placement: 'center',
    target: 'body',
  },
  {
    disableBeacon: true,
    content: <h2>Semua jawaban ada di KBBI loh!</h2>,
    placement: 'center',
    target: 'body',
  },
  {
    disableBeacon: true,
    content: <h2>Tebak kata, manfaatkan clue huruf hijau, kuning, dan abu, dan lewati levelnya !</h2>,
    placement: 'center',
    target: 'body',
  },
]

export function Wordle() {
  const {
    submitWord,
    room,
    onAbortLevel,
    onNextLevel,
    onLevelSelect,
    player,
    onPurchasePowerup,
    onSelectLevel,
    setLevels,
    level,
    island,
    world,
    setConfirm,
  } = useContext(AppContext)
  const minWordLength = 5
  const {
    showError: showErrorAlert,
    showSuccess: showSuccessAlert,
    isVisible,
  } = useAlert()
  const wordListref = useRef() as React.MutableRefObject<HTMLDivElement>;
  const rowTotalHeight = 68;
  const [currentGuess, setCurrentGuess] = useState('')
  const [isGameWon, setIsGameWon] = useState(false)
  const [currentRowClass, setCurrentRowClass] = useState('')
  const [isGameLost, setIsGameLost] = useState(false)
  const [isPopUpAttempt, setIsPopUpAttempt] = useState(false)
  const [loading, setLoading] = useState(false)
  const [isDarkMode, setIsDarkMode] = useState(false)
  const [isHighContrastMode, setIsHighContrastMode] = useState(
    getStoredIsHighContrastMode()
  )
  const [errorMessage, setErrorMessage] = useState<string | undefined>(
    undefined
  )
  const [selectedLevel, setSelectedLevel] = useState(undefined)

  const [maxGuess, setMaxGuess] = useState(6)
  const [maxWord, setMaxWord] = useState(5)
  const [isRevealing, setIsRevealing] = useState(false)
  const [didSeeAd, setDidSeeAd] = useState(false)

  const [guesses, setGuesses] = useState<string[]>([])
  const [revealedLetters, setRevealedLetters] = useState<string[]>([])
  const [solution, setSolution] = useState<string[]>([])

  const [stats, setStats] = useState(() => loadStats())
  const [scrollOffset, setScrollOffset] = useState(0)
  const [tutorial, setTutorial] = useState<boolean>(false)
  const [guessTutorialIndex, setguessTutorialIndex] = useState<number>(0)

  const [run, setRun] = useState(false);
  const [steps, setSteps] = useState<Step[]>([]);
  const [tourKey, setTourKey] = useState(0);
  const [handleDisable, setClickDisable] = useState(false);
  const [handleClickSpotlight, setClickSpotligh] = useState(false)
  const [isAdditionalAttempt, setIsAdditionalAttempt] = useState(false)

  useEffect(() => {
    if (isDarkMode) {
      document.documentElement.classList.add('dark')
    } else {
      document.documentElement.classList.remove('dark')
    }

    if (isHighContrastMode) {
      document.documentElement.classList.add('high-contrast')
    } else {
      document.documentElement.classList.remove('high-contrast')
    }
  }, [isDarkMode, isHighContrastMode])

  useEffect(() => {
    console.log(`tiap masuk level`, level.id, level.stateId)
    if (level) {
      setMaxGuess(level.maxGuess)
      setMaxWord(level.maxWordLength)
      setGuesses(level.guesses)
      setSolution(level.correctWords)
      setRevealedLetters(level.revealedLetters)
      setIsGameLost(false)
      setIsGameWon(false)
    }
  }, [level.id, level.stateId])

  const canSeeAd = useMemo(() => {
    return player?.canSeeAd && (player?.ticket_balance < 1 && player?.ticket_plus_balance < 1)
  }, [player])

  // const showError = useCallback(
  //   (msg) => {
  //     alert(msg)
  //   },
  //   [isVisible]
  // )
  
  const onExitConfirmed = () => {
    if (window.flutter_inappwebview) {
      console.log('CLOSE!')
      return window.flutter_inappwebview.callHandler('onMatchEnded', '')
    }

    alert('Exit!')
  }


  console.log('ini level', level)

  useEffect(() => {
    if(level) {
      if(level.is_tutorial) {
        // console.log(`oiiit`, level.id)
        setTutorial(true)
        setSteps(initSteps)
        currentStat = StatusTutorial.INIT
        setRun(true)
      }
      if(level.is_tutorial_tahi){
        setTutorial(true)
        setSteps(stepTahi)
        currentStat = StatusTutorial.TAHI
        setRun(true)
      }
    }
    // const svg = d3.select(ref.current)

    // const xScale = d3
    //   .scaleLinear()
    //   .domain([0, bgDimension.width])
    //   .range([0, width])

    // const yScale = d3
    //   .scaleLinear()
    //   .domain([0, bgDimension.height])
    //   .range([0, (16 / 9) * width])

    // svg
    //   .selectAll('.bg')
    //   // @ts-ignore
    //   .data([world.imageUrl], (d: string) => d)
    //   .enter()
    //   .append('svg:image')
    //   .attr('width', xScale(bgDimension.width))
    //   .attr('height', yScale(bgDimension.height))
    //   .attr('xlink:href', (d: any) => d)
    //   .style('opacity', 0)
    //   .on('load', function () {
    //     d3.select(this)
    //       .transition()
    //       .duration(500)
    //       .ease(d3.easeLinear)
    //       .style('opacity', 1)
    //   })

    const levelCompletedListener = room?.onMessage(
      'level:completed',
      ({ level, levels }) => {
        setLevels?.(levels)
        setTimeout(() => {
          setIsGameWon(true)
        }, 100)
      }
    )

    const levelFailedListener = room?.onMessage(
      'level:failed',
      ({ level, levels }) => {
        setLevels?.(levels)
        setTimeout(() => {
          setIsGameLost(true)
        }, 100)
      }
    )

    const popUpAttemptListener = room?.onMessage(
      'level:popup:add-attempt',
      (message) => {
        // setLevels?.(levels)
        setTimeout(() => {
          setIsAdditionalAttempt(true)
        }, 100)
      }
    )

    const addAttemptListener = room?.onMessage(
      'level:adds:accepted',
      (message) => {
        // setLevels?.(levels)
        setTimeout(() => {
          setIsAdditionalAttempt(false)
        }, 100)
      }
    )

    const adStoreListener = room?.onMessage('adds:store', (data) => {
      console.log(`[Wordle.tsx] Listener adds:store called!`, data)
      // setIsGameLost(false)
      setDidSeeAd(true)
    })

    const guessAcceptedListener = room?.onMessage('guess_accepted', (data) => {
      setSolution(data.correctWords)
      setGuesses(data.guesses)
      setRevealedLetters(data.revealedLetters)
      setCurrentGuess('')
      
      if(tutorial) {
        handleAfterTutorial(data.guesses.length,data.maxGuess)
      }
  
        setIsRevealing(true)
        // turn this back off after all
        // chars have been revealed
        setTimeout(() => {
          setIsRevealing(false)
        }, REVEAL_TIME_MS * level.wordLength)
    })

    const guessErrorListener = room?.onMessage('guess_error', (data) => {
      setCurrentRowClass('jiggle')
      console.log(data.message)
      return showErrorAlert(data.message, {
        onClose: clearCurrentRowClass,
      })
    })

    const powerupError = room?.onMessage('powerup:error', ({ message }) => {
      // showError(message)
      setErrorMessage(message)
    })

    room?.onMessage('powerup:effect', (data) => {
      setRevealedLetters(data.revealedLetters)
      setSolution(data.correctWords)
    })

    // const levelRefreshListener = room?.onMessage('level:refresh', ({level}) => {
    //   console.log(`di client harus refresh`)
    //   setMaxGuess(level.maxGuess)
    //   setMaxWord(level.maxWordLength)
    //   setGuesses(level.guesses)
    //   setSolution(level.correctWords)
    //   setRevealedLetters(level.revealedLetters)
    // })

    return () => {
      /**
       * @ris: masukin ke array biar ga cape
       */
      powerupError()
      guessErrorListener()
      levelCompletedListener()
      levelFailedListener()
      guessAcceptedListener()
      adStoreListener()
      popUpAttemptListener()
      addAttemptListener()
      // levelRefreshListener()
    }
  }, [])

  const clearCurrentRowClass = () => {
    setCurrentRowClass('')
  }

  // useEffect(() => {
  //   saveGameStateToLocalStorage({ guesses, solution })
  // }, [guesses])

  const handleAfterTutorial = (attemp: number, maxAttemp: number) => {
    if(currentStat == StatusTutorial.GUESS) {
      if(attemp >= (maxAttemp - 2)) {
        currentStat = StatusTutorial.G
        setTimeout(() => {
          setSteps(stepG)
          setRun(true)
        }, timerTutorial)
      }
    }
  }

  const onChar = (value: string) => {
    if (
      unicodeLength(`${currentGuess}${value}`) <= level.wordLength &&
      guesses.length < level.maxGuess &&
      !isGameWon
    ) {
      // console.log(`bisa ngapain?`, `${currentGuess}${value}`)
      if(tutorial){
        setRun(false)
      if(guesses.length === 0) {
        switch(currentStat){
          case StatusTutorial.R:
            setClickDisable(true)
            currentStat = StatusTutorial.A
            setTimeout(() => {
                setClickDisable(false)
                setSteps(stepA)
                setRun(true)
            }, timerTutorial)
           break;
          case StatusTutorial.A:
            setClickDisable(true)
            currentStat = StatusTutorial.G
            setTimeout(() => {
                setClickDisable(false)
                setSteps(stepG)
                setRun(true)
            }, timerTutorial)
           break;
           case StatusTutorial.G:
            setClickDisable(true)
            currentStat = StatusTutorial.U
            setTimeout(() => {
                setClickDisable(false)
                setSteps(stepU)
                setRun(true)
            }, timerTutorial)
            break;
          case StatusTutorial.U:
            setClickDisable(true)
            currentStat = StatusTutorial.ENTER
            setTimeout(() => {
                setClickDisable(false)
                setSteps(stepEnter)
                setRun(true)
            }, timerTutorial)
           break;
          default:
            break;
        }
      }

      if(guesses.length > 1) {
        switch(currentStat){
          case StatusTutorial.G:
            setClickDisable(true)
            currentStat = StatusTutorial.U
            setTimeout(() => {
                setClickDisable(false)
                setSteps(stepU)
                setRun(true)
            }, timerTutorial)
            break;
           case StatusTutorial.U:
              setClickDisable(true)
              if(`${currentGuess}${value}` === 'GURU'){
                // console.log(`ini kebaca ga? done`, `${currentGuess}${value}`)
                currentStat = StatusTutorial.ENTER
                setTutorial(false)
                setTimeout(() => {
                  setClickDisable(false)
                  setSteps(stepEnter)
                  setRun(true)
              }, timerTutorial)
            break;  
            }
            currentStat = StatusTutorial.R
            setTimeout(() => {
                setClickDisable(false)
                setSteps(stepR)
                setRun(true)
            }, timerTutorial)
           break;
          case StatusTutorial.R:
            setClickDisable(true)
            currentStat = StatusTutorial.U
            setTimeout(() => {
                setClickDisable(false)
                setSteps(stepU)
                setRun(true)
            }, timerTutorial)
           break;
          default:
            break;
        }
      }
    }
      
      // if(currentStat === StatusTutorial.R) {
      //   currentStat = StatusTutorial.A
      //   setTimeout(() => {
      //     setSteps(stepA)
      //     setRun(true)
      //   }, 500)
      // }
      setCurrentGuess(`${currentGuess}${value}`)
    }
  }

  const onDelete = () => {
    setCurrentGuess(
      new GraphemeSplitter().splitGraphemes(currentGuess).slice(0, -1).join('')
    )
  }

  const onEnter = () => {
    // if (isGameWon || isGameLost) {
    //   return
    // }

    setRun(false)
    if(tutorial) {
      if(currentStat === StatusTutorial.ENTER) {
        currentStat = StatusTutorial.DONE
        setTimeout(() => {
          setSteps(stepDone)
          setRun(true)
        }, timerTutorial)
      }
    }

    if (!(unicodeLength(currentGuess) === level.wordLength)) {
      setCurrentRowClass('jiggle')
      return showErrorAlert(NOT_ENOUGH_LETTERS_MESSAGE, {
        onClose: clearCurrentRowClass,
      })
    }

    if (level) {
      // Room.send('guess', { word: currentGuess })
      submitWord?.(currentGuess, level.id)
    }
  }

  const consumePowerUp = useCallback(
    (e, powerup) => {
      if (loading) {
        return
      }

      setLoading(true)

      // const unknownLetterIndexes = chain(hints.split(''))
      //   .map((d: string, index: number) =>
      //     d.includes('?') ? index : undefined
      //   )
      //   .filter((d: number | undefined) => typeof d !== 'undefined')
      //   .value()

      room?.send('powerup:consume', {
        id: powerup.id,
      })

      e.currentTarget.blur()

      setTimeout(() => {
        setLoading(false)
      }, 500)
    },
    [loading]
  )

  const onScroll = (value: number) => {
    if (value < 0 || value > (level.maxGuess - minWordLength) * rowTotalHeight) {
      return;
    }

    wordListref.current.scrollTo({
      top: value,
      left: 0,
      behavior: 'smooth'
    })

    console.log(value)
    setScrollOffset(value === 0 ? 0 : value / 68)
  }

  const canScrollDown = useCallback(() => {
    return scrollOffset * rowTotalHeight < (level.maxGuess - minWordLength) * rowTotalHeight
  }, [rowTotalHeight, level.maxGuess, scrollOffset])

  const handleJoyrideCallback = (data: CallBackProps) => {
    const { action, index, status, type } = data;
    const finishedStatuses: string[] = [STATUS.FINISHED, STATUS.SKIPPED];
    const eventStatuses: string[] = [EVENTS.STEP_AFTER, EVENTS.TARGET_NOT_FOUND]
    
    if (finishedStatuses.includes(status)) {
      // Need to set our running state to false, so we can restart if we click start again.
      setRun(false);
      setTourKey((prevKey) => prevKey + 1);

      switch(currentStat){
        case StatusTutorial.INIT:
          setClickDisable(true)
          currentStat = StatusTutorial.R
          setTimeout(() => {
            setClickDisable(false)
            setClickSpotligh(true)
            setSteps(stepR)
            setRun(true)
          }, timerTutorial)
          break;
        case StatusTutorial.DONE:
          setRun(false);
          currentStat = StatusTutorial.GUESS
          break;
        default:
          break;
      }
    }
  };

  let bgBody = '' 
  let bgHead = '' 
  let bgFoot = '' 
  let bgTitle = ''
  if (level.difficulty === 'VE') {
    bgBody = `url(${require('../../assets/bg-noob-body.png')})`
    bgHead = `url(${require('../../assets/bg-noob-head.png')})`
    bgFoot = `url(${require('../../assets/bg-noob-footer.png')})`
    bgTitle = `url(${require('../../assets/Header-Hintbox-easy.png')})`
  } else if (level.difficulty === 'E') {
    bgBody = `url(${require('../../assets/bg-noob-body.png')})`
    bgHead = `url(${require('../../assets/bg-noob-head.png')})`
    bgFoot = `url(${require('../../assets/bg-noob-footer.png')})`
    bgTitle = `url(${require('../../assets/Header-Hintbox-easy.png')})`
  } else if (level.difficulty === 'M') {
    bgBody = `url(${require('../../assets/bg-easy-body.png')})`
    bgHead = `url(${require('../../assets/bg-easy-head.png')})`
    bgFoot = `url(${require('../../assets/bg-easy-footer.png')})`
    bgTitle = `url(${require('../../assets/Header-Hintbox-medium.png')})`
  } else if (level.difficulty === 'H') {
    bgBody = `url(${require('../../assets/bg-medium-body.png')})`
    bgHead = `url(${require('../../assets/bg-medium-head.png')})`
    bgFoot = `url(${require('../../assets/bg-medium-footer.png')})`
    bgTitle = `url(${require('../../assets/Header-Hintbox-hard.png')})`
  } else if (level.difficulty === 'VH') {
    bgBody = `url(${require('../../assets/bg-hard-body.png')})`
    bgHead = `url(${require('../../assets/bg-hard-head.png')})`
    bgFoot = `url(${require('../../assets/bg-hard-footer.png')})`
    bgTitle = `url(${require('../../assets/Header-Hintbox-extremelyhard.png')})`
  } 
  // birthday
  // if (level.difficulty === 'VE') {
  //   bgBody = `url(${require('../../assets/bg-noob-body.png')})`
  //   bgHead = `url(${require('../../assets/bg-noob-head-birthday.png')})`
  //   bgFoot = `url(${require('../../assets/bg-noob-footer-birthday.png')})`
  //   bgTitle = `url(${require('../../assets/Header-Hintbox-easy-birthday.png')})`
  // } else if (level.difficulty === 'E') {
  //   bgBody = `url(${require('../../assets/bg-noob-body.png')})`
  //   bgHead = `url(${require('../../assets/bg-noob-head-birthday.png')})`
  //   bgFoot = `url(${require('../../assets/bg-noob-footer-birthday.png')})`
  //   bgTitle = `url(${require('../../assets/Header-Hintbox-easy-birthday.png')})`
  // } else if (level.difficulty === 'M') {
  //   bgBody = `url(${require('../../assets/bg-easy-body.png')})`
  //   bgHead = `url(${require('../../assets/bg-easy-head-birthday.png')})`
  //   bgFoot = `url(${require('../../assets/bg-easy-footer-birthday.png')})`
  //   bgTitle = `url(${require('../../assets/Header-Hintbox-medium-birthday.png')})`
  // } else if (level.difficulty === 'H') {
  //   bgBody = `url(${require('../../assets/bg-medium-body.png')})`
  //   bgHead = `url(${require('../../assets/bg-medium-head-birthday.png')})`
  //   bgFoot = `url(${require('../../assets/bg-medium-footer-birthday.png')})`
  //   bgTitle = `url(${require('../../assets/Header-Hintbox-hard-birthday.png')})`
  // } else if (level.difficulty === 'VH') {
  //   bgBody = `url(${require('../../assets/bg-hard-body.png')})`
  //   bgHead = `url(${require('../../assets/bg-hard-head-birthday.png')})`
  //   bgFoot = `url(${require('../../assets/bg-hard-footer-birthday.png')})`
  //   bgTitle = `url(${require('../../assets/Header-Hintbox-extremelyhard-birthday.png')})`
  // } 

  return (
    <div
      style={{
        // backgroundImage: `url(${island.gameplayBgUrl})`,
        backgroundImage: `url(${pattern})`,
        backgroundRepeat: 'repeat',
        overflow: 'auto',
        display: 'block',
        height: '100vh'
      }}
    >
      <Joyride
        key={tourKey}
        callback={handleJoyrideCallback}
        continuous
        hideCloseButton
        run={run}
        scrollToFirstStep
        hideBackButton
        spotlightClicks={handleClickSpotlight}
        disableOverlayClose
        disableCloseOnEsc
        steps={steps}
        styles={{
          options: {
            zIndex: 10000,
          },
        }}
        locale={{
          next: 'Lanjut',
          last: 'Yuk! Mulai'
        }}
      />
      <Header title={level.title} onBack={() => {
        setConfirm?.({
          title: "Yakin Keluar?",
          callback: () => {
            onSelectLevel?.()
          }
        })
      }} />
      <div style={{
        backgroundImage: bgBody,
        backgroundSize: '100%',
        backgroundRepeat: 'repeat-y',
        position: 'relative',
        margin: '25px 0 30px 0',
      }}>
        <div 
          className="head-bg" 
          style={{ 
            backgroundImage: bgHead,
            backgroundSize: '100%',
            backgroundRepeat: 'no-repeat',
            position: 'absolute',
            left: 0,
            right: 0,
            top: '-30px',
            height: '150px',
          }}>
        </div>
        <div 
          className="foot-bg" 
          style={{ 
            backgroundImage: bgFoot,
            backgroundSize: '100%',
            backgroundRepeat: 'no-repeat',
            position: 'absolute',
            left: 0,
            right: 0,
            bottom: '-30px',
            height: '150px',
            zIndex: '1',
          }}>
        </div>
        <div className='demo__hints text-center relative pt-8'>
        { !!level && 
            <div className='flex-row text-white justify-center inline-flex items-center' style={{
              fontFamily: "'Lilita One', sans-serif",
              fontSize: level.title.length > 10 ? 13 : 18,
              fontWeight: 400,
              color: '#fff',
              letterSpacing: '1px',
              backgroundImage: bgTitle,
              backgroundSize: '100%',
              backgroundRepeat: 'no-repeat',
              width: '153px',
              // height: '43px',
              position: 'absolute',
              top: '-32px',
              left: '50%',
              transform: 'translateX(-50%)',
              height: '52px', //birthday
              paddingBottom: '12px'
            }}>
              {level.title}
            </div> 
          }
        { level.mode === 1 && <div className="flex align-center justify-center">
          <img src={level.assetUrl} className='p-2' alt="" style={{
            width: '71%',
            height: 'auto'
          }}></img>
        </div>}
        <Grid
          guesses={[level.unlockedHints.join('')]}
          solution={solution}
          currentGuess={''}
          isRevealing={false}
          currentRowClassName={currentRowClass}
          maxGuess={1}
          maxWord={level.wordLength}
          identity='hints'
        />
        </div>

        <div className="flex flex-col justify-center items-center grow demo__guesses">
          {/* {level.maxGuess > minWordLength && (
            <div
              onClick={() => onScroll(wordListref.current.scrollTop - rowTotalHeight)}
              className="flex mb-2 w-screen justify-center items-center text-center"
              style={{
                padding: '2px 8px',
                opacity: scrollOffset !== 0 ? 1 : 0
              }}
            >
              <img src={require('../../assets/ic-chevron-top.png')} width={32} />
            </div>
          )} */}

          <div
            ref={wordListref}
            style={{
              margin: '10px 0'
            }}
            className="z-10"
          >
            <Grid
              guesses={guesses}
              solution={solution}
              currentGuess={currentGuess}
              isRevealing={isRevealing}
              currentRowClassName={currentRowClass}
              maxGuess={level.maxGuess}
              maxWord={level.wordLength}
              identity='guesses'
            />
          </div>

          {/* {level.maxGuess > minWordLength && (
            <div
              onClick={() => onScroll(wordListref.current.scrollTop + rowTotalHeight)}
              className="flex mt-2 w-screen justify-center items-center text-center"
              style={{
                padding: '2px 8px',
                opacity: canScrollDown() ? 1 : 0
              }}
            >
              <img
                src={require('../../assets/ic-chevron-bottom.png')}
                width={32}
                alt=""
              />
            </div>
          )} */}
        </div>
        {level.mode === 0 && <div className='demo__powerups z-10 relative'>
          <Powerup />
        </div>}
      </div>
      {/* </div>
        <a
          href="javascript:void(0)"
          className={cx(' text-white font-bold py-2 px-4 rounded', {
            'bg-blue-500 hover:bg-blue-700': !loading,
            'bg-gray-500': loading,
          })}
          onClick={beliPowerUp}
        >
          Beli Powerup: Reveal
        </a>
      </div> */}
      {/* <div className="flex flex-1 items-center justify-center mb-5">
        <a
          href="javascript:void(0)"
          className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
          onClick={consumePowerUp}
        >
          Tambah Waktu x 10
        </a>
      </div> */}
      {/* <div>Revealed letters: {JSON.stringify(revealedLetters)}</div>
      <div>Unlocked hints: {JSON.stringify(solution)}</div> */}
      <div className="p-2 demo__keyboards">
        <Keyboard
          onChar={onChar}
          onDelete={onDelete}
          onEnter={onEnter}
          revealedLetters={revealedLetters}
          guesses={guesses}
          isRevealing={isRevealing}
          solution={solution}
          hints={level.unlockedHints}
          maxWord={maxWord}
          handleDisable={handleDisable}
        />
      </div>
      <LevelFinishedModal
        isOpen={isGameWon && !isRevealing}
        // handleCancel={() => {
        //   setIsGameWon(false)
        //   setGuesses([])
        //   setRevealedLetters([])
        //   setSolution([])
        //   setCurrentGuess('')
        //   onLevelSelect?.()
        //   onSelectLevel?.()
        // }}
        handleRetry={() => alert('on retry')}
        handleNext={() => {
          setIsGameWon(false)
          setGuesses([])
          setRevealedLetters([])
          setSolution([])
          setCurrentGuess('')
          onLevelSelect?.()
          onSelectLevel?.()
        }}
        handleCancel={() => {
          onExitConfirmed()
        }}
      />

      <LevelFailedModal
        isOpen={isGameLost && !isRevealing}
        handleDismiss={() => {
          setIsGameWon(false)
          setIsGameLost(false)
        }}
        // handleCancel={() => {
        //   setIsGameWon(false)
        //   setGuesses([])
        //   setRevealedLetters([])
        //   setSolution([])
        //   setCurrentGuess('')
        //   onLevelSelect?.()
        //   onSelectLevel?.()
        // }}
        handleCancel={() => {
          onExitConfirmed()
        }}
        handleBack={() => {
          setIsGameWon(false)
          setGuesses([])
          setRevealedLetters([])
          setSolution([])
          setCurrentGuess('')
          onLevelSelect?.()
          onSelectLevel?.()
        }}
        handleOk={(lvl) => {
          setSelectedLevel(() => {
            onSelectLevel?.(lvl.id)
            return undefined
          })
        }}
      />

      <AdditionalAttemptModal
        handleCancel={() => setIsAdditionalAttempt(false)}
        isOpen={isAdditionalAttempt && !isRevealing}
        text={`Jangan menyerah dulu,
        tambah ${level.additional_attempt} kali percobaan lagi yo!`}
      />
    </div>
  )
}
