import React from 'react'
import ReactDOM from 'react-dom'
import './index.css'
import { Main } from './pages/Main/Main'
import reportWebVitals from './reportWebVitals'
import { AlertProvider } from './context/AlertContext'
import { AppProvider } from './app.context'

// Mock for testing!
if (window && !window.flutter_inappwebview) {
  window.flutter_inappwebview = {
    callHandler: (event: string, active: boolean) => {
      return new Promise((resolve) => {
        setTimeout(() => {
          resolve({ event, active })
        }, 1000)
      })
    },
  }
}

ReactDOM.render(
  <React.StrictMode>
    <AlertProvider>
      <AppProvider>
        <Main />
      </AppProvider>
    </AlertProvider>
  </React.StrictMode>,
  document.getElementById('root')
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
