import { BaseModal } from './BaseModal'
import * as d3 from 'd3'
import { useMemo, useCallback, useContext } from 'react'
import { useWindowDimensions } from '../../hooks/dimension.hook'
import { AppContext } from '../../app.context'

type Props = {
  isOpen: boolean
  title?: string
  text: string
  handleCancel?: () => void
}

export const ErrorGenericModal = ({
  isOpen,
  title = "Oops!",
  text,
  handleCancel = () => undefined,
}: Props) => {
  const { width, height } = useWindowDimensions()
  const { room, onExit } = useContext(AppContext)
  const popupSize = useMemo(() => {
    return width * 0.8
  }, [width])

  const scale = useCallback(
    (value: number) => {
      const _scale = d3.scaleLinear().domain([0, 600]).range([0, popupSize])
      return _scale(value)
    },
    [popupSize]
  )

  return (
    <BaseModal
      isOpen={isOpen}
      handleClose={handleCancel}
      containerStyle={{ padding: 16 }}
    >
      <div className="relative">
        <img src={require('../../assets/X-Button.png')} width={34} 
          style={{
            position: 'absolute',
            left: scale(-43),
            top: scale(120),
          }} 
          alt=""
          onClick={handleCancel}
        />
        <img src={require('../../assets/Main_Body_UI.png')} width={popupSize} alt="" />
        <img src={require('../../assets/Header-UI.png')} 
          style={{
            position: 'absolute',
            left: '50%',
            top: scale(115),
            transform: 'translate(-50%, 0)',
            width: '135px'
          }}  alt="" />
        {/* <div
          className="text-white"
          style={{
            position: 'absolute',
            left: '50%',
            top: scale(315),
            transform: 'translate(-50%, 0)',
            fontWeight: '700',
            fontSize: 20,
            color: '#FFF',
            fontFamily: "'Encode Sans Semi Condensed', sans-serif",
            textShadow: "2px 2px #54101B",
          }}
        >
          { title }
        </div> */}

        <div
          style={{
            position: 'absolute',
            left: '50%',
            top: scale(260),
            transform: 'translate(-50%, 0)',
            fontWeight: '400',
            fontSize: 15,
            color: '#6d1a12',
            fontFamily: "'Lilita One', sans-serif",
          }}
        >
          {text}
        </div>
        
        <div
          className="text-white"
          style={{
            position: 'absolute',
            left: '50%',
            bottom: scale(90),
            transform: 'translate(-50%, 0)',
            width: '100%',
            zIndex: 10
          }}
        >
        <div
          style={{
            width: '100px',
            height: '43px',
            display: 'inline-block',
            verticalAlign: 'middle',
            backgroundImage: `url(${require('../../assets/OK-Button.png')})`,
            backgroundSize: '100%',
            backgroundRepeat: 'no-repeat',
            padding: '0px',
            margin: '5px',
          }}            
          onClick={handleCancel}
        >
          {/* <p style={{ 
              margin: 0, 
              color: '#FFF', 
              textShadow: "1px 1px #54101B",
              fontSize: '14',
              fontFamily: "'Encode Sans Semi Condensed', sans-serif",
              fontWeight: '600',
              display: 'inline-block', verticalAlign: 'middle',
            }}
          >
            Ok, Lanjut
          </p> */}
        </div>
        </div>
      </div>
    </BaseModal>
  )
}
