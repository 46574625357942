import { useContext } from 'react'

import { AppContext } from '../../app.context'
import { Header } from '../../components/header/header'

export const WorldSelect = () => {
  const { worlds, onSelectWorld } = useContext(AppContext)

  const imageWidget = (world: any) => {
    return (
      <div
        style={{
          width: 64,
          height: 64,
          backgroundColor: 'white',
          flex: 1,
          alignItems: 'center',
          justifyContent: 'center',
          textAlign: 'center',
          display: 'flex',
        }}
      >
        <img src={world.imageUrl} width="80%" height="80%" />
      </div>
    )
  }

  return (
    <div className="max-w mx-auto">
      <Header />

      <div className="grid grid-cols-5 gap-5">
        {worlds.map((world: any) => (
          <div
            key={world.id}
            onClick={() => {
              onSelectWorld?.(world.id)
            }}
          >
            <div>{imageWidget(world)}</div>
            {world.title}
          </div>
        ))}
      </div>
    </div>
  )
}
