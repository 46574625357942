import { createContext, useCallback, useEffect, useMemo, useState } from 'react'
import * as Colyseus from 'colyseus.js'
import { ErrorGenericModal } from './components/modals/ErrorGenericModal'
import { ConfirmModal } from './components/modals/ConfirmModal'

const APP_DEFAULT_STATE = {
  ready: false,
  error: undefined,
  levels: [],
}

export interface iAppContext {
  ready: boolean
  tiketError?: string
  levels: any[]
  level?: any
  onSelectLevel?: (levelId?: string) => void
  submitWord?: (word: string, levelId: string) => void
  room?: Colyseus.Room
  player?: any
  onAbortLevel?: () => void
  onNextLevel?: () => void
  onLevelSelect?: () => void
  onPurchasePowerup?: (powerupid: string) => void
  islands?: any
  island?: any
  worlds?: any
  world?: any
  onSelectWorld?: (worldId?: string) => void
  onSelectIsland?: (islandId?: string) => void
  setLevels?: (levels: any) => void
  setSelectedLevel?: (selectedLevel: any) => void
  setTiketError?: (value?: string) => void
  setGenericError?: (value?: string) => void
  onExit?: () => void
  genericError?: string
  confirm?: { title?: string; callback?: any }
  setConfirm?: (data: { title?: string; callback?: any }) => void
  onHandleAds?: () => void
  onHandleAttemptAds?: (params: any) => void
  canSeeAd?: boolean
  goToShop?: () => void
  onFetchWorld?: () => void
  onFetchLevel?: (room: any) => void
}

export const AppContext = createContext<iAppContext>(APP_DEFAULT_STATE)

export const AppProvider = ({ children }: any) => {
  const [ready, setReady] = useState(false)
  const [tiketError, setTiketError] = useState<string | undefined>()
  const [genericError, setGenericError] = useState<string | undefined>()
  const [levels, setLevels] = useState<any>([])
  const [selectedLevel, setSelectedLevel] = useState<any>([])
  const [level, setLevel] = useState<any | undefined>()
  const [worlds, setWorlds] = useState<any>([])
  const [loading, setLoading] = useState(false)
  const [room, setRoom] = useState<Colyseus.Room<any>>()
  const [player, setPlayer] = useState<any>()
  const [islands, setIslands] = useState<any>([])
  const [island, setIsland] = useState<any>()
  const [world, setWorld] = useState<any>()
  const [confirm, setConfirm] = useState<
    | {
        title?: string
        callback?: any
      }
    | undefined
  >()

  const urlParams = new URLSearchParams(window.location.href)
  const levelId = urlParams.get('level_id')
  console.log(`${levelId}`)

  const $client = new Colyseus.Client(process.env.REACT_APP_COLYSEUS_URL)

  const authenticate = async (game_token: string) => {
    console.log(`Authenticating with play token: ${game_token}`)

    try {
      const response = await fetch(
        `${process.env.REACT_APP_GAME_SERVER_URL}/play`,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            game_token: game_token,
          }),
        }
      )

      const responseData = await response.json()

      if (!responseData || (responseData && responseData.err)) {
        return onExit()
      }

      // console.log(
      //   `Authenticated: Ok`,
      //   JSON.stringify(responseData.data, null, 2)
      // )

      return responseData.data
    } catch (e) {
      onExit()
    }
  }

  const onExitConfirmed = () => {
    if (window.flutter_inappwebview) {
      console.log('CLOSE!')
      return window.flutter_inappwebview.callHandler('onMatchEnded', '')
    }

    alert('Exit!')
  }

  const onExit = (text?: string) => {
    setConfirm({
      title: 'Yakin Keluar?',
      callback: () => onExitConfirmed(),
    })
  }

  const goToShop = () => {
    if (window.flutter_inappwebview) {
      console.log('CLOSE!')
      return window.flutter_inappwebview.callHandler('onShop', 'shop')
    }
  }

  const initColyseus = async (session_id: string, access_token: string) => {
    console.log(`Initializing Colyseus...`, session_id, access_token)

    const colyseusRoom: Colyseus.Room<any> = await $client.joinById(
      session_id,
      {
        access_token,
      }
    )

    console.log(`Initializing Colyseus...: OK`)

    colyseusRoom.onStateChange((state) => {
      if (state.ready) {
        setReady(state.ready)
      }

      if (state.player) {
        console.log('Player changed')
        let player = {
          ...state.player,
        }
        player.powerups = Array.from(state.player.powerups.values())
        // console.log(`nawn ieu??`, JSON.stringify(state.player, undefined, 2), state.player.diamond)
        setPlayer(player)
      }

      if (state.level && state.level.id) {
        // console.log('Level updated', JSON.stringify(state.level))

        setLevel({
          ...state.level,
          activePowerUps: Array.from(
            state.level.activePowerUps?.values() ?? []
          ),
          correctWords: Array.from(state.level.correctWords?.values() ?? []),
          dependency: Array.from(state.level.dependency?.values() ?? []),
          guesses: Array.from(state.level.guesses?.values() ?? []),
          position: {
            ...(state.level.position ?? {}),
          },
          revealedLetters: Array.from(
            state.level.revealedLetters?.values() ?? []
          ),
          unlockedHints: Array.from(state.level.unlockedHints?.values() ?? []),
        })
      } else {
        // DIDIE TAHHH
        // setLevel(undefined)
      }
    })

    setRoom(colyseusRoom)

    colyseusRoom.onMessage('game:init', ({ worlds, player, ready }) => {
      // setLevels(Array.from(levels))
      setPlayer(player)
      setReady(ready)
      setLoading(false)
      setWorlds(Object.values(worlds))

      console.log('game:init', {
        worlds,
        player,
        ready,
      })

      onFetchLevel(colyseusRoom)
    })

    colyseusRoom.onMessage('game:levels', (data) => {
      console.log('game:levels', data)

      console.log(
        data.levels.map((d: any) => {
          return `level ${d.level} unlocked: ${d.unlocked}, completed: ${d.completed}`
        })
      )

      setLevels(Array.from(data.levels))
    })

    colyseusRoom.onMessage('game:islands', (data) => {
      console.log('game:islands', data)
      setLoading(false)
      setIslands(Array.from(data.islands))
    })

    colyseusRoom.onMessage('game:worlds', (data) => {
      console.log('game:worlds', data)
      // setLoading(false)
    })

    // colyseusRoom.onMessage('level:start', (data) => {
    //   setLoading(false)
    //   setLevel(data.level)
    // })

    // colyseusRoom.onMessage('level:ended', (data) => {
    //   setLoading(false)
    //   setLevel(undefined)
    // })

    colyseusRoom.onMessage('world:selected', ({ world, islands }) => {
      setLoading(false)
      setWorld(world)
      setIslands(islands)
    })

    colyseusRoom.onMessage('island:selected', ({ island, levels }) => {
      setLoading(false)
      setIsland(island)
      setLevels(levels)
      console.log('game:levels', levels)
    })

    colyseusRoom.onMessage('island:select:error', () => {
      setLoading(false)
      return alert('Island belum terbuka')
    })

    colyseusRoom.onMessage('levels:updated', (data) => {
      setLoading(false)
      setLevels(data.levels)
      console.log('game:levels', data.levels)
    })

    colyseusRoom.onMessage('level:fetch', (data) => {
      console.log('level:fetch', data)
      setLevel(data.level)
    })

    colyseusRoom.onMessage('adds:check', (data) => {
      // console.log('Can see adds ?', data)
      if (data) {
        if (data.status) {
          if (window.flutter_inappwebview) {
            window.flutter_inappwebview
              .callHandler('onAddsShow', true)
              .then((d: any) => {
                if (d) {
                  colyseusRoom.send('adds:store', data)
                  setTiketError(undefined)
                  console.log(data)
                  if (data.nextAction === 'level:retry') {
                    colyseusRoom.send('level:select', {
                      id: data.payload.levelId,
                    })
                    return undefined
                  } else if (data.inGame) {
                    return undefined
                  }

                  colyseusRoom.send('level:select', {
                    id: data.payload.levelId,
                  })
                }
              })
          }
        }
      }

      if (window.flutter_inappwebview) {
        window.flutter_inappwebview.callHandler('onAddsShow', false)
      }
    })

    colyseusRoom.onMessage('adds:store', (data) => {
      console.log(`[app.context.tsx] Listener adds:store called!`, data)
      setTiketError(undefined)
    })

    colyseusRoom.onLeave((code) => {
      console.log('onLeave', code)
      switch (code) {
        default:
          alert('The room has been closed by the server.')
          break
      }

      window.location.href = '/'
    })

    colyseusRoom.onError((code, message) => {
      console.log('onError', code, message)
    })

    // colyseusRoom.state.levels.onAdd = function (x: any) {
    //   console.log('level added', x.id)
    // }

    // colyseusRoom.state.levels.onAdd = function () {
    //   console.log('levels changed')
    // }
    return colyseusRoom as Colyseus.Room<any>
  }

  const canSeeAd = useMemo(() => {
    return (
      player?.canSeeAd &&
      player?.ticket_balance < 1 &&
      player?.ticket_plus_balance < 1
    )
  }, [player])

  const onLevelSelect = () => {
    setLevel(undefined)
  }

  const onFetchWorld = () => {
    room?.send('world:fetch')
    return console.log(`lagi list world`)
  }

  const onFetchLevel = (room: any) => {
    console.log('levelId', levelId)
    room?.send('level:fetch', { id: levelId })
    return console.log(`lagi fetch level`)
  }

  const onSelectIsland = (islandId?: string) => {
    if (!ready || loading) return

    if (!islandId) {
      return room?.send('island:reset')
    }

    const selectIsland = islands.find((island: any) => island.id === islandId)

    if (!selectIsland) {
      setGenericError('Island not found')
      throw new Error('Island not found')
    }

    room?.send('island:select', {
      id: islandId,
    })
  }

  const onSelectWorld = (worldId?: string) => {
    console.log(`kesini ga?`)
    if (!ready || loading) return
    setLoading(true)

    if (!worldId) {
      return room?.send('world:reset')
    }

    const selectedWorld = worlds.find((world: any) => world.id === worldId)

    if (!selectedWorld) {
      setLoading(false)
      setGenericError('World not found')
      throw new Error('World not found')
    }

    room?.send('world:select', {
      id: worldId,
    })
  }

  const onHandleAds = useCallback(() => {
    // console.log(`ajig`, level)
    let params = level
      ? { nextAction: 'level:retry', payload: { levelId: level.id } }
      : null
    room?.send('adds:check', params)
  }, [level, room])

  const onHandleAttemptAds = (params: any) => {
    // if (window.flutter_inappwebview) {
    //   window.flutter_inappwebview
    //     .callHandler('onAddsShow', true)
    //     .then((d: any) => {
    //       if (d) {
    //         console.log(`sampe sini`)
    //         room?.send('level:adds:accept')
    //         return undefined
    //       }
    //     })
    // }
    room?.send('level:adds:accept')
    return undefined
  }

  const onAbortLevel = useCallback(() => {
    if (!level) {
      return
    }

    room?.send('level:abort', level?.id)
  }, [room, level])

  const onSelectLevel = (levelId?: string) => {
    // console.log(`lari kesini?`, room, ready, loading)
    if (!ready || loading) return

    // console.log(`lari kesini 2?`)
    if (!levelId) {
      console.log(`di reset dlu ya?`)
      return room?.send('level:reset')
    }

    if (!level) {
      setGenericError('Level tidak ditemukan')
      throw new Error('Level not found')
    }
    console.log(`lari kesini5?`, room)
    console.log('levelid', levelId)
    room?.send('level:select', {
      id: levelId,
    })
    // console.log(`harusnya done`)
  }
  //   [room, levels, loading, ready]
  // )

  const onNextLevel = () => {
    if (!ready || loading) return
    setLoading(true)

    room?.send('level:next')
  }

  const submitWord = (word: string, levelId: string) => {
    room?.send('guess', word)
  }

  const onPurchasePowerup = (powerupId: string) => {
    room?.send('powerup:purchase', { id: powerupId, quantity: 1 })
  }

  useEffect(() => {
    ;(async () => {
      try {
        const game_token = window.location.href.match(/play=([a-zA-Z0-9\-_]+)/)

        if (!game_token || !game_token[1]) {
          console.error('Play token not found!')
          return onExit()
        }

        const response = await authenticate(game_token[1])

        if (!response) {
          console.log(`Failed to exchange token`)
          return
        }

        const room = await initColyseus(
          response.session_id,
          response.access_token
        )

        room.onMessage('level:error:generic', (data) => {
          setLoading(false)
          setGenericError(data.message)
        })

        room.onMessage('level:error:tiket', (data) => {
          setLoading(false)
          setTiketError(data.message)
        })
      } catch (e) {
        alert(`Gagal terhubung dengan server, coba lagi nanti`)
      }
    })()
  }, [])

  return (
    <AppContext.Provider
      value={{
        ready: ready && !loading,
        tiketError,
        levels,
        onSelectLevel,
        level,
        submitWord,
        room,
        player,
        onAbortLevel,
        onNextLevel,
        onLevelSelect,
        onPurchasePowerup,
        islands,
        island,
        worlds,
        world,
        onSelectWorld,
        onSelectIsland,
        setLevels,
        setTiketError,
        setGenericError,
        genericError,
        onExit,
        confirm,
        setConfirm,
        onHandleAds,
        onHandleAttemptAds,
        canSeeAd,
        goToShop,
        onFetchWorld,
        onFetchLevel,
      }}
    >
      {children}

      {!!confirm && (
        <ConfirmModal
          title={confirm.title}
          isOpen={!!confirm}
          handleCancel={() => {
            setConfirm?.(undefined)
          }}
          handleOk={() => {
            const cb = confirm.callback
            setConfirm?.(undefined)
            setTimeout(() => {
              cb()
            })
          }}
        />
      )}
    </AppContext.Provider>
  )
}
