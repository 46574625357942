import { useCallback, useContext, useMemo, useState } from 'react'
import cx from 'classnames'

import { AppContext } from '../../app.context'
import { PowerupItem } from './PowerupItem'

export const Powerup = () => {
  const { player, level, room } = useContext(AppContext)

  const [loading, setLoading] = useState<boolean>(false)

  const consumePowerUp = useCallback(
    (e, powerup) => {
      if (loading) {
        return
      }

      setLoading(true)

      room?.send('powerup:consume', {
        id: powerup.id,
      })

      e.currentTarget.blur()

      // setTimeout(() => {
      setLoading(false)
      // }, 500)
    },
    [loading]
  )

  const powerups = useMemo(() => {
    // console.log(`haiii`, Array.from(player.powerups.values()))
    const playerPowerUps: any[] = Array.from(player.powerups.values())
    return playerPowerUps.filter((d) => level.activePowerUps.includes(d.id))
  }, [player.powerups, level.activePowerUps])

  const renderAvailablePowerups = () => {
    return powerups.map((powerup: any) => {
      return <PowerupItem powerup={powerup} onConsumePowerup={consumePowerUp} />
    })
  }

  if (!powerups) {
    return null
  }

  return (
    <div className="flex flex-row justify-center mb-1">
      {/* <div className="powerup-placeholder-head" /> */}
      <div className="powerup-placeholder flex-row flex">
        {renderAvailablePowerups()}
      </div>
      {/* <div className="powerup-placeholder-tail" /> */}
    </div>
  )
}
