import { Fragment } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import { XCircleIcon } from '@heroicons/react/outline'
import cx from 'classnames'

type Props = {
  title?: string
  children: React.ReactNode
  isOpen: boolean
  handleClose: () => void
  isGameWon?: boolean
  isGameLost?: boolean
  transparent?: boolean
  containerStyle?: any
}

export const BaseModal = ({
  title,
  children,
  isOpen,
  handleClose,
  transparent,
  containerStyle = {},
}: Props) => {
  // if (isGameWon) {
  //   title = 'Selamat! Kamu berhasil menebak Kata dengan benar'
  // }
  // if (isGameLost) {
  //   title = `Ups! Kamu masih gagal menebak nih! Coba lagi Yo!`
  // }
  // if (isStarted) {
  //   title = `Event Belum Dimulai!`
  // }
  // if (isEnded) {
  //   title = `Event Sudah Berakhir`
  // }

  return (
    <Transition.Root show={isOpen} as={Fragment} appear={true} >
      <Dialog
        as="div"
        className="fixed z-10 inset-0 overflow-y-auto"
        onClose={handleClose}
      >
        <div className="flex items-center justify-center min-h-screen py-10 px-4 text-center sm:block sm:p-0">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-300"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          {/* <span
            className="hidden sm:inline-block sm:align-middle sm:h-screen"
            aria-hidden="true"
          >
            &#8203;
          </span> */}
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 scale-50 sm:translate-y-0 sm:scale-50"
            enterTo="opacity-100 translate-y-0 scale-100 sm:scale-100"
            leave="ease-in duration-300"
            leaveFrom="opacity-100 translate-y-0 scale-100 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 scale-50 sm:translate-y-0 sm:scale-50"
          >
            <div
              className={cx(
                'inline-block align-bottom rounded-lg text-left transform transition-all sm:align-middle sm:max-w-sm sm:w-full',
              )}
            >
              {/* <div className="absolute right-4 top-4">
                <XCircleIcon
                  className="h-6 w-6 cursor-pointer dark:stroke-white"
                  onClick={() => handleClose()}
                />
              </div> */}
              <div style={containerStyle}>
                <div className="text-center">
                  {!!title && (
                    <Dialog.Title
                      as="h3"
                      className="text-lg leading-6 font-medium text-gray-900 dark:text-gray-100"
                    >
                      {title}
                    </Dialog.Title>
                  )}
                  <div className="mt-4">{children}</div>
                </div>
              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  )
}
