import { BaseModal } from './BaseModal'
import * as d3 from 'd3'
import { useMemo, useCallback, useContext } from 'react'
import { useWindowDimensions } from '../../hooks/dimension.hook'
import { AppContext } from '../../app.context'

type Props = {
  isOpen: boolean
  title?: string
  text: string
  ads?: boolean
  handleCancel?: () => void
}

export const AdditionalAttemptModal = ({
  isOpen,
  title = "Oops!",
  text,
  handleCancel = () => undefined,
}: Props) => {
  const { width, height } = useWindowDimensions()
  const { room, onExit, onHandleAttemptAds, level } = useContext(AppContext)
  const popupSize = useMemo(() => {
    return width * 0.8
  }, [width])

  const scale = useCallback(
    (value: number) => {
      const _scale = d3.scaleLinear().domain([0, 600]).range([0, popupSize])
      return _scale(value)
    },
    [popupSize]
  )

  function cx(arg0: { 'btn-ad-disabled': any }): string | undefined {
    throw new Error('Function not implemented.')
  }

  return (
    <BaseModal
      isOpen={isOpen}
      handleClose={handleCancel}
      containerStyle={{ padding: 16 }}
    >
      <div className="relative">
        <img src={require('../../assets/X-Button.png')} width={34} 
          style={{
            position: 'absolute',
            left: scale(-43),
            top: scale(120),
          }} 
          alt=""
          onClick={handleCancel}
        />
        <img src={require('../../assets/Main_Body_UI.png')} width={popupSize} alt="" />
        <img src={require('../../assets/Header-UI.png')} 
          style={{
            position: 'absolute',
            left: '50%',
            top: scale(115),
            transform: 'translate(-50%, 0)',
            width: '135px'
          }}  alt="" />
        {/* <div
          className="text-white"
          style={{
            position: 'absolute',
            left: '50%',
            top: scale(315),
            transform: 'translate(-50%, 0)',
            fontWeight: '700',
            fontSize: 20,
            color: '#FFF',
            fontFamily: "'Encode Sans Semi Condensed', sans-serif",
            textShadow: "2px 2px #54101B",
          }}
        >
          { title }
        </div> */}

        <div
          style={{
            position: 'absolute',
            left: '50%',
            top: scale(250),
            transform: 'translate(-50%, 0)',
            fontWeight: '400',
            fontSize: 14,
            color: '#6d1a12',
            width: '80%',
            fontFamily: "'Lilita One', sans-serif",
          }}
        >
          {text}
        </div>
        
        <div
          style={{
            position: 'absolute',
            left: '50%',
            bottom: scale(100),
            transform: 'translate(-50%, 0)',
            width: '100%',
            zIndex: 10
          }}
        >
          <div
            style={{
              backgroundColor: 'transparent',
              height: scale(80),
              width: scale(184),
              display: 'inline-flex',
              verticalAlign: 'middle',
              alignItems: 'center',
              justifyContent: 'center',
              margin: '5px',
              position: 'relative',
              backgroundImage: `url(${require('../../assets/green-plain.png')})`,
              backgroundSize: '100%',
              backgroundRepeat: 'no-repeat'
            }}
            onClick={() => (onHandleAttemptAds?.({inGame: true}))}
          >
            <p style={{ 
                margin: 0, 
                color: '#FFF', 
                textShadow: "1px 2px rgba(0, 0, 0, 0.4)",
                fontSize: '20px',
                fontFamily: "'Encode Sans Semi Condensed', sans-serif",
                fontWeight: '700',
              }}
            >
              {level.retry_cost} 
            </p>
            <img src={require('../../assets/yo-token.png')} width={21} className="ml-1" alt="" />
          </div>

          <div
            onClick={() => {
                handleCancel()
                room?.send('level:giveup', level)
              }
            }
            style={{
              backgroundColor: 'transparent',
              height: scale(80),
              width: scale(184),
              display: 'inline-block',
              verticalAlign: 'middle',
              margin: '5px',
              position: 'relative'
            }}
          >
            <img src={require('../../assets/Tidak-Button.png')} alt="" />
          </div>
        </div>
        {/* <div
            // className={cx({
            //   'btn-ad-disabled': disableAd
            // })}
            style={{
              width: '115px',
              height: '44px',
              display: 'inline-block',
              verticalAlign: 'middle',
              backgroundImage: `url(${require('../../assets/Button-Nonton-new.png')})`,
              backgroundSize: '100%',
              padding: '0px',
              margin: '0 3px',
              backgroundRepeat: 'no-repeat'
            }}
            onClick={() => onHandleAttemptAds?.({inGame: true})}
          ></div> */}
          {/* <p style={{ 
              margin: 0, 
              color: '#FFF', 
              textShadow: "1px 1px #54101B",
              fontSize: '14',
              fontFamily: "'Encode Sans Semi Condensed', sans-serif",
              fontWeight: '600',
              display: 'inline-block', verticalAlign: 'middle',
            }}
          >
            Ok, Lanjut
          </p> */}
        {/* </div> */}
        </div>
    </BaseModal>
  )
}
