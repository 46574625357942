import React, {
  useContext,
} from 'react'
import { BaseModal } from './BaseModal'
import * as d3 from 'd3'
import { useMemo, useCallback } from 'react'
import { useWindowDimensions } from '../../hooks/dimension.hook'
import { AppContext } from '../../app.context'
import ReactPlayer from 'react-player'

type Props = {
  isOpen: boolean
  handleCancel?: () => void
  handleNext?: () => void
  handleRetry?: () => void
}

export const LevelFinishedModal = ({
  isOpen,
  handleCancel = () => undefined,
  handleNext,
  handleRetry,
}: Props) => {
  const { width, height } = useWindowDimensions()
  const { island, level } =
    useContext(AppContext)
  const popupSize = useMemo(() => {
    return width * 0.8
  }, [width])

  const scale = useCallback(
    (value: number) => {
      const _scale = d3.scaleLinear().domain([0, 600]).range([0, popupSize])
      return _scale(value)
    },
    [popupSize]
  )

  const headPopup = () => {
    const random = Math.floor(Math.random() * 3) + 1;
    switch (random) {
      case 1:
        return require('../../assets/cakep.png')
      case 2:
        return require('../../assets/hebat.png')
      case 3:
        return require('../../assets/keren.png')
    }
  }

  return (
    <BaseModal isOpen={isOpen} handleClose={handleCancel} transparent={true}>
      <div className="relative">
        <img src={require('../../assets/X-Button.png')} width={34} 
          style={{
            position: 'absolute',
            left: scale(-43),
            top: scale(120),
            zIndex: 9
          }} 
          alt=""
          onClick={handleCancel}
        />

        <img src={require('../../assets/Main_Body_UI.png')} width={popupSize} alt="" />
        <ReactPlayer url={require('../../assets/GUI-TEBAK-YO-MENANG-birthday.webm')} loop playing muted width='100vw' height='calc((16/9) * 100vw)'
        // <ReactPlayer url={require('../../assets/GUI-MENANG.webm')} loop playing muted width='100vw' height='calc((16/9) * 100vw)'
        style={{position: 'absolute', top: scale(-470), left: '50%', transform: 'translateX(-50%)' }} onError={(e) => console.log(e)}/>

        <div
          style={{
            position: 'absolute',
            left: '50%',
            transform: 'translate(-50%, 0)',
            top: scale(115),
            backgroundImage: `url(${require('../../assets/Header-UI-box.png')})`,
            backgroundSize: '100%',
            width: '135px',
            height: '45px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center'
          }}
        >
          <img src={headPopup()} 
            style={{
              width: '85px'
            }}  alt="" />
        </div>
        <div
          style={{
            position: 'absolute',
            left: '50%',
            top: scale(240),
            transform: 'translate(-50%, 0)',
            zIndex: 9,
            color: '#6d1a12',
            fontFamily: "'Lilita One', sans-serif",
            width: '80%',
            fontSize: 15,
            fontWeight: 400,
          }}
        >
          Selamat kamu mendapatkan
        </div>
        <div
          style={{
            position: 'absolute',
            left: '50%',
            top: scale(290),
            transform: 'translate(-50%, 0)',
            zIndex: 9,
            color: '#6d1a12',
            fontFamily: "'Lilita One', sans-serif",
            width: '80%',
            fontSize: 20,
            fontWeight: 400,
          }}
        >
          <img src={require('../../assets/yo-gem.png')} alt="" width={26} style={{ display: 'inline-block', verticalAlign: 'middle' }} />
          <span style={{ display: 'inline-block', verticalAlign: 'middle' }}>+{ level.rewards[0].reward_amount ?? 0 }</span>
        </div>

        <div
          onClick={() => handleCancel?.()}
          className="text-white"
          style={{
            position: 'absolute',
            left: '50%',
            bottom: scale(100),
            transform: 'translate(-50%, 0)',
            width: '100%',
            zIndex: 10
          }}
        >
          <div
            style={{
              width: '100px',
              height: '43px',
              display: 'inline-block',
              verticalAlign: 'middle',
              backgroundImage: `url(${require('../../assets/OK-Button.png')})`,
              backgroundSize: '100%',
              backgroundRepeat: 'no-repeat',
              padding: '0px',
              margin: '5px',
            }}
          >
            {/* <p style={{ 
                margin: 0, 
                color: '#FFF', 
                textShadow: "1px 1px #54101B",
                fontSize: '14',
                fontFamily: "'Encode Sans Semi Condensed', sans-serif",
                fontWeight: '600',
                display: 'inline-block', verticalAlign: 'middle',
              }}
            >
              Ok, Lanjut
            </p> */}
          </div>
        </div>
      </div>
    </BaseModal>
  )
}
