import React, { useContext, useEffect, useMemo, useState } from 'react'
import ReactLoading from 'react-loading'
import { HashRouter, Route, Router, Routes } from 'react-router-dom'

import { AppContext } from '../../app.context'
import logo from '../../assets/title.svg'
import { IslandSelect } from '../IslandSelect/IslandSelect'
import { LevelSelect } from '../LevelSelect/LevelSelect'
import { Wordle } from '../Wordle/Wordle'
import { WorldSelect } from '../WorldSelect/WorldSelect'
import { ErrorModal } from '../../components/modals/ErrorModal'
import { ErrorGenericModal } from '../../components/modals/ErrorGenericModal'
import { LevelSelectModal } from '../../components/modals/LevelSelectModal'
import { style } from 'd3'

export const Main = () => {
  const {
    ready,
    tiketError,
    onSelectLevel,
    level,
    world,
    island,
    islands,
    onSelectIsland,
    setTiketError,
    setGenericError,
    genericError,
    room,
    player,
    canSeeAd,
    onHandleAds,
    onFetchLevel
  } = useContext(AppContext)
  
  const [selectedLevel, setSelectedLevel] = useState(Boolean)
  const urlParams = new URLSearchParams(window.location.href);
  const levelId = urlParams.get('level_id');


  // const canSeeAd = useMemo(() => {
  //   return player?.canSeeAd && (player?.ticket_balance < 1 && player?.ticket_plus_balance < 1)
  // }, [player])

  const renderContent = () => {
    // console.log(`ready: ${ready}, world: ${world}, level: ${JSON.stringify(level)}`)

    if (!ready || !levelId || !player) {
      return (
        <div className="flex min-h-screen mx-auto items-center justify-center">
          <ReactLoading type="spin" color="#fe336a" height={100} width={100} />
        </div>
      )
    }

    console.log('hehe123', level)
    console.log('selectedLevel', selectedLevel)

    if (!selectedLevel) {
      return <LevelSelectModal
        isOpen={true}
        level={level}
        handleOk={(lvl) => {
          setSelectedLevel(() => {
            onSelectLevel?.(lvl.id)
            return true
          })
        }}
        handleCancel={() => setSelectedLevel(false)}
      />
    }

    if (selectedLevel) {
      return <Wordle />
    }

    // if (island) {
    //   return <LevelSelect />
    // }

    // if (world && islands.length !== 0) {
    //   return (
    //     <IslandSelect
    //       islands={islands}
    //       world={world}
    //       onSelectIsland={onSelectIsland}
    //     />
    //   )
    // }

    return <WorldSelect />
  }

  return (
    // <div className="mx-auto sm:px-6 lg:px-8 bg-gradient-to-b from-hero-500 to-hero-200 h-screen">
    <div
      className="mx-auto bg-black min-h-screen flex w-screen"
      style={{ flexDirection: 'column' }}
    >
      {renderContent()}
      {!!tiketError && (
        <ErrorModal
          text={tiketError}
          isOpen={!!tiketError}
          handleCancel={() => setTiketError?.(undefined)}
          handleAds={() => onHandleAds?.()}
        />
      )}
      {!!genericError && (
        <ErrorGenericModal
          text={genericError}
          isOpen={!!genericError}
          handleCancel={() => setGenericError?.(undefined)}
        />
      )}
    </div>
  )
}
