import { useCallback, useContext, useMemo } from 'react'
import { BaseModal } from './BaseModal'
import { AppContext } from '../../app.context'
import { useWindowDimensions } from '../../hooks/dimension.hook'
import cx from 'classnames'
import * as d3 from 'd3'

type Props = {
  isOpen: boolean
  level: any
  handleCancel: () => void
  handleOk?: (level: any) => void
}

export const LevelSelectModal = ({
  isOpen,
  level,
  handleCancel,
  handleOk,
}: Props) => {
  const { player, room, onHandleAds } = useContext(AppContext)
  const { width, height } = useWindowDimensions()

  const popupSize = useMemo(() => {
    return width * 0.8
  }, [width])

  const disableAd = useMemo(() => {
    return player.remain_ads === 0
  }, [player])

  const scale = useCallback(
    (value: number) => {
      const _scale = d3.scaleLinear().domain([0, 600]).range([0, popupSize])
      return _scale(value)
    },
    [popupSize]
  )

  const powerups = useMemo(() => {
    // console.log(`test ris`, Array.from(player.powerups.values()))
    if (!player || !level) {
      return []
    }

    // const playerPowerUps: any[] = Array.from(player.powerups.values()) ERROR PERLU DI BETULIN
    const playerPowerUps: any[] = Array.from(player.powerups)
    return playerPowerUps.filter((d) => level.activePowerUps.includes(d.id))
  }, [player, level])

  const costFree = useMemo(() => {

    if(player.ticket_balance === 0 && player.ticket_plus_balance === 0) {
      // console.log(`kesini? if 1`)
      return true
    } else if(player.ticket_balance === 0 && player.ticket_plus_balance > 0) {
      // console.log(`kesini? if 2`, level.costType, (level.costType === 'T' && costFree))
      return false
    }

    return true
  }, [player])

  if (!isOpen || !level) {
    return null
  }

  return (
    <BaseModal 
      isOpen={isOpen} handleClose={handleCancel} transparent={true}>
      <div className="relative">
        {/* <img src={require('../../assets/X-Button.png')} width={34} 
          style={{
            position: 'absolute',
            left: scale(-43),
            top: scale(120),
          }} 
          alt=""
          onClick={handleCancel}
        /> */}
        {/* <img src={require('../../assets/birthday-play.png')} width={popupSize} alt="" style={{
          position: 'absolute',
          top: scale(-270),
        }} /> */}
        <img src={require('../../assets/Main-Body-Play.png')} width={popupSize} alt="" />
        <div
          style={{
            position: 'absolute',
            left: '50%',
            top: scale(-10),
            transform: 'translate(-50%, 0)',
            fontWeight: '400',
            fontSize: level.title.length > 10 ? 13 : 20,
            color: '#fff',
            fontFamily: "'Lilita One', sans-serif",
            textShadow: "2px 2px #6d1a12",
            backgroundImage: `url(${require('../../assets/header-play.png')})`,
            backgroundSize: '100%',
            backgroundRepeat: 'no-repeat',
            width: '130px',
            height: '45px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center'
          }}
        >
          {(level.title ?? '').toUpperCase()}
        </div>
        <div
          style={{
            position: 'absolute',
            left: '50%',
            top: scale(130),
            transform: 'translate(-50%, 0)',
            fontWeight: '400',
            fontSize: 12,
            color: '#6d1a12',
            fontFamily: "'Lilita One', sans-serif",
            textAlign: "left",
            paddingLeft: 28,
            width: "71%"
          }}
        >
          <img 
            src={require('../../assets/ic-info.png')} 
            width={20} 
            alt=""  
            style={{
              position: 'absolute',
              left: 0,
              top: '50%',
              transform: 'translate(0,-50%)',
            }}
          />
          Jawaban berhubungan dengan kata-kata {(level.title ?? '').toUpperCase()}
        </div>
        <div
          style={{
            position: 'absolute',
            left: '50%',
            top: scale(243),
            transform: 'translate(-50%, 0)',
            width: "73%",
            textAlign: 'center'
          }}
        >
          <span 
            style={{
              display: 'inline-block',
              width: '31%',
              borderRight: '1px solid rgba(255, 255, 255, 0.3)'
            }}
          >
            <img 
              src={require('../../assets/yo-gem.png')} 
              width={23} 
              alt="" 
              style={{
                display: 'inline-block',
                verticalAlign: 'middle',
              }}
            />
            <span 
              style={{
                display: 'inline-block',
                verticalAlign: 'middle',
                color: '#6d1a12',
                fontSize: '14',
                fontFamily: "'Lilita One', sans-serif",
                fontWeight: '400',
                marginLeft: 5
              }}
            >
              { level.rewards[0].reward_amount ?? 0 }
            </span>
          </span>
          <span 
            style={{
              display: 'inline-block',
              width: '31%',
              borderRight: '1px solid rgba(255, 255, 255, 0.3)'
            }}
          >
            <img 
              src={require('../../assets/ic-time.png')} 
              width={20} 
              alt="" 
              style={{
                display: 'inline-block',
                verticalAlign: 'middle',
              }}
            />
            <span 
              style={{
                display: 'inline-block',
                verticalAlign: 'middle',
                color: '#6d1a12',
                fontSize: '14',
                fontFamily: "'Lilita One', sans-serif",
                fontWeight: '400',
                marginLeft: 5
              }}
            >
              { level.timer }
            </span>
          </span>
          <span 
            style={{
              display: 'inline-block',
              width: '31%'
            }}
          >
            <img 
              src={require('../../assets/ic-attemp.png')} 
              width={20} 
              alt="" 
              style={{
                display: 'inline-block',
                verticalAlign: 'middle',
              }}
            />
            <span 
              style={{
                display: 'inline-block',
                verticalAlign: 'middle',
                color: '#6d1a12',
                fontSize: '14',
                fontFamily: "'Lilita One', sans-serif",
                fontWeight: '400',
                marginLeft: 5
              }}
            >
              {level.maxGuess}
            </span>
          </span>
        </div>
        <div
          style={{
            position: 'absolute',
            left: '50%',
            top: scale(335),
            transform: 'translate(-50%, 0)',
            width: "83%",
            textAlign: 'center'
          }}
        >
        <div className="">
          {powerups && powerups.length !== 0 && (
            <>
              {powerups.map((powerup: any) => {
                return (
                  <div
                    key={powerup.id}
                    className={'text-sm text-white font-bold rounded center'}
                    style={{ margin: '0 3px', display: 'inline-block' }}
                  >
                    {/* {powerup.description} x {powerup.amount} */}
                    <img src={powerup.iconUrl} alt="" style={{width: '44px'}} />
                  </div>
                )
              })}
            </>
          )}
          {Array.from(Array(Array.from(player.powerups).length - powerups.length).keys()).map(() => {
            return (<div
              style={{ margin: '0 3px', display: 'inline-block' }}
            >
              <img src={require('../../assets/power-locked.png')} alt="" style={{width: '44px'}} />
            </div>)
            })
          }
          </div>
        </div>

        <div
          style={{
            position: 'absolute',
            left: '50%',
            top: scale(440),
            transform: 'translate(-50%, 0)',
            width: "85%",
            textAlign: 'center'
          }}
          className='justify-items-center items-center'
        >
          <div
            onClick={() => handleOk?.(level)}
            style={{
              width: '115px',
              height: '44px',
              display: 'inline-block',
              verticalAlign: 'middle',
              backgroundImage: `url(${(level.costType === 'K' && costFree) ? require('../../assets/main-tiket-ungu.png') : require('../../assets/main-tiket-kuning.png')})`,
              backgroundSize: '100%',
              padding: '0',
              margin: '0 3px',
              backgroundRepeat: 'no-repeat'
            }}
          >
            {/* <p style={{ 
                margin: 0, 
                color: '#FFF', 
                textShadow: "1px 1px #54101B",
                fontSize: '14',
                fontFamily: "'Encode Sans Semi Condensed', sans-serif",
                fontWeight: '600',
                display: 'inline-block', verticalAlign: 'middle',
              }}
            >
              Main  
              { level.costAmount !== 0 &&
                <span style={{ margin: '0 5px 0 3px' }}>{level.costAmount}</span>
              }
            </p> */}
            {/* { level.costAmount !== 0 &&
              <img src={(level.costType === 'K' && costFree) ? require('../../assets/ticket-free.png') : require('../../assets/ticket-plus.png')} alt="" style={{ display: 'inline-block', verticalAlign: 'middle', width: '24px' }} />
            } */}
          </div>

          <div
            className={cx({
              'btn-ad-disabled': disableAd
            })}
            style={{
              width: '115px',
              height: '44px',
              display: 'inline-block',
              verticalAlign: 'middle',
              backgroundImage: `url(${require('../../assets/Button-Nonton-new.png')})`,
              backgroundSize: '100%',
              padding: '0px',
              margin: '0 3px',
              backgroundRepeat: 'no-repeat'
            }}
            onClick={() => (disableAd) ? undefined : onHandleAds?.()}
          >
          {/* <p style={{ 
              margin: 0, 
              color: '#FFF', 
              textShadow: "1px 1px #54101B",
              fontSize: '14',
              fontFamily: "'Encode Sans Semi Condensed', sans-serif",
              fontWeight: '600',
              display: 'inline-block', verticalAlign: 'middle',
            }}
          >
            Nonton
            <img 
              src={require('../../assets/icon-movie.png')} 
              alt="" 
              style={{
                display: 'inline-block',
                verticalAlign: 'middle',
                width: '20px',
                height: '20px',
                marginLeft: '4px'
              }}
            />
          </p> */}
            <span 
              style={{
                display: 'inline-block',
                position: 'absolute',
                width: '16px',
                height: '16px',
                borderRadius: '50%',
                fontFamily: "'Lilita One', sans-serif",
                color: '#6d1a12',
                fontSize: '10px',
                top: 10,
                right: 15,
              }}
            >
              {player.remain_ads}
            </span>
          </div>
          
        </div>
      </div>
    </BaseModal>
  )
}
